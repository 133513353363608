<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Steppers">
                    <template v-slot:description>
                        <div>
                            <div>import { HbStepper, HbStepperStep } from 'hummingbird-aviary';</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card class="mt-4 mb-6">

            <template v-slot:title>
                Less Steps Stepper Example
            </template>

            <hb-stepper v-model="stepper2">
                <hb-stepper-header
                    :force-resize="resizeStepper"
                >
                    <hb-stepper-step
                        @click="goToStepLessSteps(1)"
                        step="1"
                        :complete="stepper2 > 1"
                    >
                        Short Text
                    
                    </hb-stepper-step>

                    <v-divider></v-divider>

                    <hb-stepper-step
                        @click="goToStepLessSteps(2)"
                        step="2"
                        :complete="stepper2 > 2"
                    >
                        Some Really Long Text
                    </hb-stepper-step>

                    <v-divider></v-divider>

                    <hb-stepper-step
                        @click="goToStepLessSteps(3)"
                        step="3"
                        :complete="stepper2 > 3"
                        :rules="[() => false]"
                    >
                        Some Really Long Text with Extra Words
                    </hb-stepper-step>
        
                </hb-stepper-header>

            </hb-stepper>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
This example is just for demo purposes. Use the code in the next example.
</pre>
            </hb-card>

        </hb-card>
        
        <hb-card class="mt-4 mb-6">

            <template v-slot:title>
                <span class="pr-3">Stepper Example + Code</span> <span class="hb-text-light hb-font-body font-weight-regular">( You are starting on Step 2. Step 1 has been marked skipped for example purposes. Use toggle switches to play around. )</span>
            </template>

            <hb-stepper v-model="stepper">
                <hb-stepper-header
                    :force-resize="resizeStepper"
                >
                    <hb-stepper-step
                        @click="goToStep(1)"
                        step="1"
                        :complete="completed1"
                        :rules="[() => hasNoErrors1]"
                    >
                        Some Really Long Text with Extra Words
                    
                    </hb-stepper-step>

                    <v-divider></v-divider>

                    <hb-stepper-step
                        @click="goToStep(2)"
                        step="2"
                        :complete="completed2"
                        :rules="[() => hasNoErrors2]"
                    >
                        Some Really Long Text
                    </hb-stepper-step>

                    <v-divider></v-divider>

                    <hb-stepper-step
                        @click="goToStep(3)"
                        step="3"
                        :complete="completed3"
                        :rules="[() => hasNoErrors3]"
                    >
                        Short Text
                    </hb-stepper-step>
                    
                    <v-divider></v-divider>
                    
                    <hb-stepper-step
                        @click="goToStep(4)"
                        step="4"
                        :complete="completed4"
                        :rules="[() => hasNoErrors4]"
                    >
                        Some Really Long Text
                    </hb-stepper-step>
                    
                    <v-divider></v-divider>
                    
                    <hb-stepper-step
                        @click="goToStep(5)"
                        step="5"
                        :complete="completed5"
                        :rules="[() => hasNoErrors5]"
                    >
                        Text
                    </hb-stepper-step>
                    
                    <v-divider></v-divider>
                    
                    <hb-stepper-step
                        @click="goToStep(6)"
                        step="6"
                        :complete="completed6"
                        :rules="[() => hasNoErrors6]"
                    >
                        Some Really Long Text with Extra Words
                    </hb-stepper-step>
                </hb-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1" class="ma-0 pa-0">
                        <v-row no-gutters class="px-4 pt-3 pb-4" justify="center">
                            <span class="hb-font-header-3-medium">You are on Step 1</span>
                        </v-row>
                        <hb-bottom-action-bar cancel-off>
                            <template v-slot:right-actions>
                                <hb-btn v-if="hasNoErrors1" color="secondary" @click="hasNoErrors1 = false">
                                    Mark Step as Error
                                </hb-btn>
                                <hb-btn v-if="!hasNoErrors1" color="destructive" @click="hasNoErrors1 = true">
                                    Mark Step as Valid
                                </hb-btn>
                                <hb-btn color="secondary" @click="completed1 = false, stepper = 2">
                                    Skip Step
                                </hb-btn>
                                <hb-btn @click="completed1 = true, stepper = 2">
                                    Complete Step
                                </hb-btn>
                            </template>
                        </hb-bottom-action-bar>
                    </v-stepper-content>

                    <v-stepper-content step="2" class="ma-0 pa-0">
                        <v-row no-gutters class="px-5 pt-3 pb-4" justify="center">
                            <span class="hb-font-header-3-medium">You are on Step 2</span>
                        </v-row>
                        <hb-bottom-action-bar cancel-off>
                            <template v-slot:right-actions>
                                <hb-btn color="secondary" @click="stepper = 1">
                                    Go to Previous Step
                                </hb-btn>
                                <hb-btn v-if="hasNoErrors2" color="secondary" @click="hasNoErrors2 = false">
                                    Mark Step as Error
                                </hb-btn>
                                <hb-btn v-if="!hasNoErrors2" color="destructive" @click="hasNoErrors2 = true">
                                    Mark Step as Valid
                                </hb-btn>
                                <hb-btn color="secondary" @click="completed2 = false, stepper = 3">
                                    Skip Step
                                </hb-btn>
                                <hb-btn @click="completed2 = true, stepper = 3">
                                    Complete Step
                                </hb-btn>
                            </template>
                        </hb-bottom-action-bar>
                    </v-stepper-content>

                    <v-stepper-content step="3" class="ma-0 pa-0">
                        <v-row no-gutters class="px-4 pt-3 pb-4" justify="center">
                            <span class="hb-font-header-3-medium">You are on Step 3</span>
                        </v-row>
                        <hb-bottom-action-bar cancel-off>
                            <template v-slot:right-actions>
                                <hb-btn color="secondary" @click="stepper = 2">
                                    Go to Previous Step
                                </hb-btn>
                                <hb-btn v-if="hasNoErrors3" color="secondary" @click="hasNoErrors3 = false">
                                    Mark Step as Error
                                </hb-btn>
                                <hb-btn v-if="!hasNoErrors3" color="destructive" @click="hasNoErrors3 = true">
                                    Mark Step as Valid
                                </hb-btn>
                                <hb-btn color="secondary" @click="completed3 = false, stepper = 4">
                                    Skip Step
                                </hb-btn>
                                <hb-btn @click="completed3 = true, stepper = 4">
                                    Complete Step
                                </hb-btn>
                            </template>
                        </hb-bottom-action-bar>
                    </v-stepper-content>

                    <v-stepper-content step="4" class="ma-0 pa-0">
                        <v-row no-gutters class="px-4 pt-3 pb-4" justify="center">
                            <span class="hb-font-header-3-medium">You are on Step 4</span>
                        </v-row>
                        <hb-bottom-action-bar cancel-off>
                            <template v-slot:right-actions>
                                <hb-btn color="secondary" @click="stepper = 3">
                                    Go to Previous Step
                                </hb-btn>
                                <hb-btn v-if="hasNoErrors4" color="secondary" @click="hasNoErrors4 = false">
                                    Mark Step as Error
                                </hb-btn>
                                <hb-btn v-if="!hasNoErrors4" color="destructive" @click="hasNoErrors4 = true">
                                    Mark Step as Valid
                                </hb-btn>
                                <hb-btn color="secondary" @click="completed4 = false, stepper = 5">
                                    Skip Step
                                </hb-btn>
                                <hb-btn @click="completed4 = true, stepper = 5">
                                    Complete Step
                                </hb-btn>
                            </template>
                        </hb-bottom-action-bar>
                    </v-stepper-content>

                    <v-stepper-content step="5" class="ma-0 pa-0">
                        <v-row no-gutters class="px-4 pt-3 pb-4" justify="center">
                            <span class="hb-font-header-3-medium">You are on Step 5</span>
                        </v-row>
                        <hb-bottom-action-bar cancel-off>
                            <template v-slot:right-actions>
                                <hb-btn color="secondary" @click="stepper = 4">
                                    Go to Previous Step
                                </hb-btn>
                                <hb-btn v-if="hasNoErrors5" color="secondary" @click="hasNoErrors5 = false">
                                    Mark Step as Error
                                </hb-btn>
                                <hb-btn v-if="!hasNoErrors5" color="destructive" @click="hasNoErrors5 = true">
                                    Mark Step as Valid
                                </hb-btn>
                                <hb-btn color="secondary" @click="completed5 = false, stepper = 6">
                                    Skip Step
                                </hb-btn>
                                <hb-btn @click="completed5 = true, stepper = 6">
                                    Complete Step
                                </hb-btn>
                            </template>
                        </hb-bottom-action-bar>
                    </v-stepper-content>

                    <v-stepper-content step="6" class="ma-0 pa-0">
                        <v-row no-gutters class="px-4 pt-3 pb-4" justify="center">
                            <span class="hb-font-header-3-medium">You are on Step 6</span>
                        </v-row>
                        <hb-bottom-action-bar cancel-off>
                            <template v-slot:right-actions>
                                <hb-btn color="secondary" @click="stepper = 5">
                                    Go to Previous Step
                                </hb-btn>
                                <hb-btn v-if="hasNoErrors6" color="secondary" @click="hasNoErrors6 = false">
                                    Mark Step as Error
                                </hb-btn>
                                <hb-btn v-if="!hasNoErrors6" color="destructive" @click="hasNoErrors6 = true">
                                    Mark Step as Valid
                                </hb-btn>
                                <hb-btn v-if="completed6 === true" color="secondary" @click="completed6 = false">
                                    Mark Final Step as Skipped
                                </hb-btn>
                                <hb-btn @click="validateStepper()">
                                    Complete Final Step
                                </hb-btn>
                            </template>
                        </hb-bottom-action-bar>
                    </v-stepper-content>

                    <v-stepper-content step="7" class="ma-0 pa-0">
                        <v-row no-gutters class="px-4 pt-3 pb-4" justify="center">
                            <span class="hb-font-header-3-medium d-flex align-center">
                                <hb-icon color="#02AD0F" class="pr-2">mdi-check</hb-icon>
                                All Stepper Steps Complete!
                            </span>
                        </v-row>
                        <hb-bottom-action-bar cancel-off>
                            <template v-slot:right-actions>
                                <hb-btn color="secondary" @click="stepper = 6">
                                    Go Back to Previous Step
                                </hb-btn>
                            </template>
                        </hb-bottom-action-bar>
                    </v-stepper-content>
                </v-stepper-items>

            </hb-stepper>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-stepper v-model="stepper">
    &lt;hb-stepper-header :force-resize="resizeStepper">
        &lt;hb-stepper-step
            @click="goToStep(1)"
            step="1"
            :complete="completed1"
            :rules="[() => hasNoErrors1]"
        >
            Some Really Long Text with Extra Words
        
        &lt;/hb-stepper-step>

        &lt;v-divider>&lt;/v-divider>

        &lt;hb-stepper-step
            @click="goToStep(2)"
            step="2"
            :complete="completed2"
            :rules="[() => hasNoErrors2]"
        >
            Some Really Long Text
        &lt;/hb-stepper-step>

        &lt;v-divider>&lt;/v-divider>

        &lt;hb-stepper-step
            @click="goToStep(3)"
            step="3"
            :complete="completed3"
            :rules="[() => hasNoErrors3]"
        >
            Some Text
        &lt;/hb-stepper-step>
        
        &lt;v-divider>&lt;/v-divider>
        
        &lt;hb-stepper-step
            @click="goToStep(4)"
            step="4"
            :complete="completed4"
            :rules="[() => hasNoErrors4]"
        >
            Some Really Long Text
        &lt;/hb-stepper-step>
        
        &lt;v-divider>&lt;/v-divider>
        
        &lt;hb-stepper-step
            @click="goToStep(5)"
            step="5"
            :complete="completed5"
            :rules="[() => hasNoErrors5]"
        >
            Some Really Long Text
        &lt;/hb-stepper-step>
        
        &lt;v-divider>&lt;/v-divider>
        
        &lt;hb-stepper-step
            @click="goToStep(6)"
            step="6"
            :complete="completed6"
            :rules="[() => hasNoErrors6]"
        >
            Some Really Long Text with Extra Words
        &lt;/hb-stepper-step>
    &lt;/hb-stepper-header>
&lt;/hb-stepper>

&lt;v-stepper-items>
    &lt;v-stepper-content step="1" class="ma-0 pa-0">
        &lt;v-row no-gutters class="px-4 pt-3 pb-4" justify="center">
            &lt;span class="hb-font-header-3-medium">You are on Step 1&lt;/span>
        &lt;/v-row>
        &lt;hb-bottom-action-bar cancel-off>
            &lt;template v-slot:right-actions>
                &lt;hb-btn v-if="hasNoErrors1" color="secondary" @click="hasNoErrors1 = false">
                    Mark Step as Error
                &lt;/hb-btn>
                &lt;hb-btn v-if="!hasNoErrors1" color="destructive" @click="hasNoErrors1 = true">
                    Mark Step as Valid
                &lt;/hb-btn>
                &lt;hb-btn color="secondary" @click="completed1 = false, stepper = 2">
                    Skip Step
                &lt;/hb-btn>
                &lt;hb-btn @click="completed1 = true, stepper = 2">
                    Complete Step
                &lt;/hb-btn>
            &lt;/template>
        &lt;/hb-bottom-action-bar>
    &lt;/v-stepper-content>

    &lt;v-stepper-content step="2" class="ma-0 pa-0">
        &lt;v-row no-gutters class="px-5 pt-3 pb-4" justify="center">
            &lt;span class="hb-font-header-3-medium">You are on Step 2&lt;/span>
        &lt;/v-row>
        &lt;hb-bottom-action-bar cancel-off>
            &lt;template v-slot:right-actions>
                &lt;hb-btn color="secondary" @click="stepper = 1">
                    Go to Previous Step
                &lt;/hb-btn>
                &lt;hb-btn v-if="hasNoErrors2" color="secondary" @click="hasNoErrors2 = false">
                    Mark Step as Error
                &lt;/hb-btn>
                &lt;hb-btn v-if="!hasNoErrors2" color="destructive" @click="hasNoErrors2 = true">
                    Mark Step as Valid
                &lt;/hb-btn>
                &lt;hb-btn color="secondary" @click="completed2 = false, stepper = 3">
                    Skip Step
                &lt;/hb-btn>
                &lt;hb-btn @click="completed2 = true, stepper = 3">
                    Complete Step
                &lt;/hb-btn>
            &lt;/template>
        &lt;/hb-bottom-action-bar>
    &lt;/v-stepper-content>

    &lt;v-stepper-content step="3" class="ma-0 pa-0">
        &lt;v-row no-gutters class="px-4 pt-3 pb-4" justify="center">
            &lt;span class="hb-font-header-3-medium">You are on Step 3&lt;/span>
        &lt;/v-row>
        &lt;hb-bottom-action-bar cancel-off>
            &lt;template v-slot:right-actions>
                &lt;hb-btn color="secondary" @click="stepper = 2">
                    Go to Previous Step
                &lt;/hb-btn>
                &lt;hb-btn v-if="hasNoErrors3" color="secondary" @click="hasNoErrors3 = false">
                    Mark Step as Error
                &lt;/hb-btn>
                &lt;hb-btn v-if="!hasNoErrors3" color="destructive" @click="hasNoErrors3 = true">
                    Mark Step as Valid
                &lt;/hb-btn>
                &lt;hb-btn color="secondary" @click="completed3 = false, stepper = 4">
                    Skip Step
                &lt;/hb-btn>
                &lt;hb-btn @click="completed3 = true, stepper = 4">
                    Complete Step
                &lt;/hb-btn>
            &lt;/template>
        &lt;/hb-bottom-action-bar>
    &lt;/v-stepper-content>

    &lt;v-stepper-content step="4" class="ma-0 pa-0">
        &lt;v-row no-gutters class="px-4 pt-3 pb-4" justify="center">
            &lt;span class="hb-font-header-3-medium">You are on Step 4&lt;/span>
        &lt;/v-row>
        &lt;hb-bottom-action-bar cancel-off>
            &lt;template v-slot:right-actions>
                &lt;hb-btn color="secondary" @click="stepper = 3">
                    Go to Previous Step
                &lt;/hb-btn>
                &lt;hb-btn v-if="hasNoErrors4" color="secondary" @click="hasNoErrors4 = false">
                    Mark Step as Error
                &lt;/hb-btn>
                &lt;hb-btn v-if="!hasNoErrors4" color="destructive" @click="hasNoErrors4 = true">
                    Mark Step as Valid
                &lt;/hb-btn>
                &lt;hb-btn color="secondary" @click="completed4 = false, stepper = 5">
                    Skip Step
                &lt;/hb-btn>
                &lt;hb-btn @click="completed4 = true, stepper = 5">
                    Complete Step
                &lt;/hb-btn>
            &lt;/template>
        &lt;/hb-bottom-action-bar>
    &lt;/v-stepper-content>

    &lt;v-stepper-content step="5" class="ma-0 pa-0">
        &lt;v-row no-gutters class="px-4 pt-3 pb-4" justify="center">
            &lt;span class="hb-font-header-3-medium">You are on Step 5&lt;/span>
        &lt;/v-row>
        &lt;hb-bottom-action-bar cancel-off>
            &lt;template v-slot:right-actions>
                &lt;hb-btn color="secondary" @click="stepper = 4">
                    Go to Previous Step
                &lt;/hb-btn>
                &lt;hb-btn v-if="hasNoErrors5" color="secondary" @click="hasNoErrors5 = false">
                    Mark Step as Error
                &lt;/hb-btn>
                &lt;hb-btn v-if="!hasNoErrors5" color="destructive" @click="hasNoErrors5 = true">
                    Mark Step as Valid
                &lt;/hb-btn>
                &lt;hb-btn color="secondary" @click="completed5 = false, stepper = 6">
                    Skip Step
                &lt;/hb-btn>
                &lt;hb-btn @click="completed5 = true, stepper = 6">
                    Complete Step
                &lt;/hb-btn>
            &lt;/template>
        &lt;/hb-bottom-action-bar>
    &lt;/v-stepper-content>

    &lt;v-stepper-content step="6" class="ma-0 pa-0">
        &lt;v-row no-gutters class="px-4 pt-3 pb-4" justify="center">
            &lt;span class="hb-font-header-3-medium">You are on Step 6&lt;/span>
        &lt;/v-row>
        &lt;hb-bottom-action-bar cancel-off>
            &lt;template v-slot:right-actions>
                &lt;hb-btn color="secondary" @click="stepper = 5">
                    Go to Previous Step
                &lt;/hb-btn>
                &lt;hb-btn v-if="hasNoErrors6" color="secondary" @click="hasNoErrors6 = false">
                    Mark Step as Error
                &lt;/hb-btn>
                &lt;hb-btn v-if="!hasNoErrors6" color="destructive" @click="hasNoErrors6 = true">
                    Mark Step as Valid
                &lt;/hb-btn>
                &lt;hb-btn v-if="completed6 === true" color="secondary" @click="completed6 = false">
                    Mark Final Step as Skipped
                &lt;/hb-btn>
                &lt;hb-btn @click="validateStepper()">
                    Complete Final Step
                &lt;/hb-btn>
            &lt;/template>
        &lt;/hb-bottom-action-bar>
    &lt;/v-stepper-content>

    &lt;v-stepper-content step="7" class="ma-0 pa-0">
        &lt;v-row no-gutters class="px-4 pt-3 pb-4" justify="center">
            &lt;span class="hb-font-header-3-medium d-flex align-center">
                &lt;hb-icon color="#02AD0F" class="pr-2">mdi-check&lt;/hb-icon>
                All Stepper Steps Complete!
            &lt;/span>
        &lt;/v-row>
        &lt;hb-bottom-action-bar cancel-off>
            &lt;template v-slot:right-actions>
                &lt;hb-btn color="secondary" @click="stepper = 6">
                    Go Back to Previous Step
                &lt;/hb-btn>
            &lt;/template>
        &lt;/hb-bottom-action-bar>
    &lt;/v-stepper-content>
&lt;/v-stepper-items>


// force resize example in script using EventBus (you can use a different way or whatever way works - the important part is to make sure to manually force the stepper to resize when sidebars open or close if those sidebars affect the width of the stepper. Currently, HbSteppers will auto resize when the browser window is resized by the user, but when a sidebar opens or closes, we need to manually call the resize event or else the stepper will not be resized and the labels may overlap.):
data: function() {
    return {
        resizeStepper: 0,
    };
},
created() {
    EventBus.$on('design-system-sidebar-toggled', this.forceStepperResize);
},
destroyed() {
    EventBus.$off('design-system-sidebar-toggled', this.forceStepperResize);
},
methods: {
    forceStepperResize(){
        this.resizeStepper++
    }
}
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="<HbStepper> Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems1"
                class="pa-6"
            >
                <template v-slot:item.resource="{ item }">
                    <hb-link :href="item.resource" target="_blank" v-if="item.resource.length">{{ item.resource }}</hb-link>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="<HbStepperHeader> Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems2"
                class="pa-6"
            >
                <template v-slot:item.resource="{ item }">
                    <hb-link :href="item.resource" target="_blank" v-if="item.resource.length">{{ item.resource }}</hb-link>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="<HbStepperStep> Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems3"
                class="pa-6"
            >
                <template v-slot:item.resource="{ item }">
                    <hb-link :href="item.resource" target="_blank" v-if="item.resource.length">{{ item.resource }}</hb-link>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="snackbar"
            type="error"
            description="There are incomplete steps and/or steps that are marked as having errors. Please correct these before you can complete the final step."
        >
        </hb-global-notification>
        
    </div>
</template>
<script type="text/babel">

    import { EventBus } from '../../EventBus.js';

    export default {
        name: "DesignSystemSteppers",
        data: function() {
            return {
                snackbar: false,
                stepper: 2,
                stepper2: 2,
                completed1: false,
                completed2: false,
                completed3: false,
                completed4: false,
                completed5: false,
                completed6: false,
                hasNoErrors1: true,
                hasNoErrors2: true,
                hasNoErrors3: false,
                hasNoErrors4: true,
                hasNoErrors5: true,
                hasNoErrors6: true,
                propHeaders: [
                    { text: 'Name', value: 'name', width: '125' },
                    { text: 'Type', value: 'type', width: '125' },
                    { text: 'Default', value: 'default', width: '100' },
                    { text: 'Description', value: 'description' },
                    { text: 'Resource', value: 'resource' },
                ],
                propItems1: [
                    { name: 'all vuetify v-stepper props', type: '', default: '', description: 'All vuetify "v-stepper" props can be used in an "hb-stepper". See the vuetify documentation link in the next column for the list of available props.', resource: 'https://v2.vuetifyjs.com/en/api/steppers/' },
                ],
                propItems2: [
                    { name: 'force-resize', type: 'number', default: '0', description: 'Useful for when sidebar menus open, since the window is not technically being resized. Simply register a data variable at the parent level such as "resizeStepper: 0" and increment that variable: "resizeStepper++" when the sidebar opens and closes or whenever else you need. Then pass that variable to the HbStepperHeader component as the "force-resize" prop (i.e. "<hb-stepper-header :force-resize="resizeStepper">") to force a manual resize every time that variable increments.', resource: '' },
                ],
                propItems3: [
                    { name: 'complete', type: 'boolean', default: 'false', description: 'Set to true to apply "completed" visual styling to the icon in the stepper.', resource: '' },
                    { name: 'skipped', type: 'boolean', default: 'false', description: 'Set to true to apply "skipped" visual styling to the icon in the stepper.', resource: '' },
                    { name: 'cursor', type: 'boolean', default: 'true', description: 'Set to false to disable the cursor pointer when hovering over a step. You should set this to false for any step that is not supoosed to be directly clickable.', resource: '' },
                    { name: 'rules', type: 'array', default: '[]', description: 'Accepts a mixed array of types function, boolean and string. Functions pass an input value as an argument and must return either true / false or a string containing an error message. The input field will enter an error state if a function returns (or any value in the array contains) false or is a string. Then the correct "error" visual styling will be applied to the icon in the stepper.', resource: '' },
                    { name: 'all vuetify v-stepper-step props', type: '', default: '', description: 'All vuetify "v-stepper-step" props can be used in an "hb-stepper-step". See the vuetify documentation link in the next column for the list of available props.', resource: 'https://v2.vuetifyjs.com/en/api/v-stepper-step/' },
                ],
                resizeStepper: 0,
            };
        },
        created() {
            EventBus.$on('design-system-sidebar-toggled', this.forceStepperResize);
        },
        destroyed() {
            EventBus.$off('design-system-sidebar-toggled', this.forceStepperResize);
        },
        methods: {
            goToStep(number){
                this.stepper = number;
            },
            goToStepLessSteps(number){
                this.stepper2 = number;
            },
            forceStepperResize(){
                this.resizeStepper++
            },
            validateStepper(){
                if(this.completed1 && this.completed2 && this.completed3 && this.completed4 && this.completed5 && this.hasNoErrors1 && this.hasNoErrors2 && this.hasNoErrors3 && this.hasNoErrors4 && this.hasNoErrors5 && this.hasNoErrors6){
                    this.snackbar = false;
                    this.completed6 = true;
                    this.stepper = 7;
                } else {
                    this.snackbar = true;
                }
            }
        }
    }
</script>

<style scoped>

</style>